
* {
    box-sizing: border-box;
  }


  .monaco-editor .suggest-widget .monaco-list .monaco-list-rows .monaco-list-row {
   
       display: flex;
        flex-direction: row;
      
  }
  
  html,
  body {
    color: #444;
    font-family: 'Helvetica', 'Verdana', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  html {
    overflow: hidden;
  }

  .disabled {
    color: darkgray;
    pointer-events: none;
  }
  
  body {
    align-content: stretch;
    align-items: stretch;
    background: #efefef;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  
  #canvas {
    position: absolute;
  }

  .header {

    align-content: center;
    align-items: stretch;
    background: #1b3752  ;
    box-shadow:
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 2px 9px 1px rgba(0, 0, 0, 0.12),
      0 4px 2px -2px rgba(0, 0, 0, 0.2);
    color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 18.0px;
    height: 50px;
    justify-content: flex-end;
    position: fixed;
    transition: transform 0.233s cubic-bezier(0, 0, 0.21, 1) 0.1s;
    width: 100%;
    will-change: transform;
    z-index: 1000;

  }

  header a {
    color: #fff;
    text-decoration: none;
  }
  
  .header h1 {
    flex: 1;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }
  
  
  .main-container {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 55px;
    padding-left: 1em;
    padding-right: 1em;
  }
  
  .hidden {
    display: none;
  }

  #terminalData span {
    display: block;
    word-wrap: break-word;
  }

  

	.menu {
    padding-right: 20px;
    padding-left: 5px;
    position: fixed;
    top: 45px;
    left: 0;
    background-color: black;
    transition: all 0.2s ease-in-out;
    transform: translate3d(-400px, 0, 0); 
    z-index: 999;
  }

  .activeButton {
    background-color: lightgray;
  }

  .userMenu {
    
    background-color: #1A74A3;
    position: absolute;
    left: 0;
    top: 50px;
    transform: translateX(-500px);
  }

  .userMenuShow {
    transform: none;
  }
  
  .menu ul {
    list-style: none;
  }

  #chartFrame, #dataFrame {
    background-color: white;
  }

	.menuExpanded {
		transform: translate3d(0vw, 0, 0);
  }

  .buttonDiv {
    height: 50px;
    vertical-align: middle;
    margin-right: 28px;
    cursor: pointer;
  }

  .buttonDiv:hover {
    border-bottom: 2px solid white;
  }
  
  .buttonDiv img {
    width: 20px;
    vertical-align: middle;
  }
  
  .buttonDiv span {
    line-height: 50px;
    vertical-align: middle;
  }

  #updateNotification {
    margin-left: 1vw;
  }
  
  .invisible {
    opacity: 0;
  }


  /* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 998; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */ 
.modalContent {
  z-index: 999;
  position: absolute;
  right: 10%;
  top: 15%;
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.active-pane-button {
  background-color: rgba(139,173,181,.5)
}

.tabs-disabled li {
  background-color: #a3c6cf !important;
  color: gray !important;
  cursor: auto !important;
}






/* Add some styling to the scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: white; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e2e2e2; 
}